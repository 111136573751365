import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
// import { FaRegEdit, FaEye, FaSearch } from "react-icons/fa";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
const App = () => {
    const isRendered = useRef(false);
    const [currencyCode, setCurrencyCode] = useState('');
  const [currencyCode1, setCurrencyCode1] = useState('EUR');

    const [data1, setData] = useState([]);
    const [cur001, setCur001] = useState([]);
    const [show, setShow] = useState(false);
    const [modalData, setModalData] = useState(null);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        fetchData1();
    
      }, []);
      const fetchData1 = async () => {
        try {
          const url = `https://pnode.mtmpay.in/order-list`;
          const response = await axios.get(url);
       
          setData(response.data);
          console.warn(response.data);
        } catch (error) {
          console.error("Error fetching events:", error);
        }
      };
      const Submit = async (paypal_id) => {
        console.warn(paypal_id);
        try {
            const url = `https://pnode.mtmpay.in/order-checkstatus`;
            const payload = {
                orderid: paypal_id
            };
            const response = await axios.post(url, payload);
            setModalData(response.data);
            console.warn("response",response.data);
        } catch (error) {
            console.error("Error fetching events:", error);
        }
    };
    
    
    const [formData, setFormData] = useState({
        amount: '',
        product_name: '',
        quantity: ''
    });

    const handleChangeAmount = (e) => {
        const { id, value ,name} = e.target;

       
        setFormData(prevFormData => ({
            ...prevFormData,
            [id]: value
        }));
     
    };

    const handleChangeCurrency = (event) => {
      setCurrencyCode(event.target.value);

        var inputBox = document.getElementById('cur01');
    
        // Set the value of the input box
      inputBox.value = event.target.value; // Replace 'Your desired value' with the value you want to set
         const newCurrencyCode = event.target.value;
        setCurrencyCode1(newCurrencyCode);
      setCur001(event.target.value);

       const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=AR1rUg7t2R6AO1u_OzMActZSK_Waj61ohHTOZmpb7kj032QGGhfOGM57gXJFOa4yNwSOxRB0RPXR0acu&currency=${newCurrencyCode}`;
        script.async = true;

        // Load script and add to the document body
        document.body.appendChild(script);

        // Clean up function to remove the script when component unmounts or currency code changes
        return () => {
            document.body.removeChild(script);
        };
      

    };
  
     useEffect(() => {
        // Create script element for PayPal SDK
        // const script = document.createElement('script');
        // script.src = `https://www.paypal.com/sdk/js?client-id=YOUR_CLIENT_ID&currency=${currencyCode1}`;
        // script.async = true;

        // // Load script and add to the document body
        // document.body.appendChild(script);

        // // Clean up function to remove the script when component unmounts or currency code changes
        // return () => {
        //     document.body.removeChild(script);
        // };
    }, [currencyCode1]); // useEffect will re-run whenever currencyCode changes


    const [eorderData, SetorderData] = useState("");
  useEffect(() => {
      
    
        // alert(currencyCode)
        const renderPayPalButtons = () => {
            
            const orderData = {
                currency_code: currencyCode,
                amount: formData.amount,
                product_name: formData.product_name,
                quantity: formData.quantity
            };

            if (currencyCode === "" || formData.amount === "" ||formData.product_name==="" || formData.quantity==="") {
               
                  return;
            
              }
            localStorage.setItem("currency_code" , currencyCode);
            localStorage.setItem("amount" , formData.amount);
            localStorage.setItem("product_name" , formData.product_name)
            localStorage.setItem("quantity" , formData.quantity)
            // SetorderData(orderData);

                
                if (isRendered.current) return;
                
                window.paypal.Buttons({
                    createOrder: async (data, actions) => {
                      const currency_code =  localStorage.getItem("currency_code")
                      const amount =  localStorage.getItem("amount")
                      const product_name =  localStorage.getItem("product_name")
                      const quantity =  localStorage.getItem("quantity")
                      const orderData = {
                        currency_code: currency_code,
                        amount: amount,
                        product_name: product_name,
                        quantity: quantity
                    };
                        // console.warn("formData", orderData)
                        // alert(orderData)
                    try {
                        const response = await axios.post('https://pnode.mtmpay.in/api/orders',orderData);
                        return response.data.id; // Return the order ID
                    } catch (error) {
                        console.error('Create Order Error:', error);
                        throw new Error('Failed to create order');
                    }
                },
                onApprove: async (data, actions) => {
                    try {
                        const response = await axios.post(`https://pnode.mtmpay.in/api/orders/${data.orderID}/capture`);
                        console.warn('Capture result', response.data);
                        alert('Payment successful!');
                    } catch (error) {
                        console.error('Capture Order Error:', error);
                        alert('Payment failed!');
                    }
                }
            }).render('#paypal-buttons-container');

            isRendered.current = true;
        };

        // console.warn("formData", formData);
        
        if (window.paypal) {
            renderPayPalButtons();
        } else {
            window.addEventListener('load', renderPayPalButtons);
        }

        return () => {
            window.removeEventListener('load', renderPayPalButtons);
        };
    }, [formData, currencyCode]);
    const isFormValid = () => {
        return formData.amount && formData.product_name && formData.quantity && currencyCode;
    };

    const getStatusClass = (status) => {
        switch(status) {
          case 'COMPLETED':
            return 'badge bg-success';
          case 'PENDING':
            return 'badge bg-warning text-dark';
          case 'FAILED':
            return 'badge bg-danger';
          default:
            return 'badge bg-secondary';
        }
      };

    return (
        <>
        <div className="App  text-center container mt-5">
          <Form className="row g-3">
               <Form.Group className='mb-2 col-md-6 form_details'>
    <select id="currencyDropdown" className='form-select' name='currencyCode' value={currencyCode} onChange={handleChangeCurrency}>
  
        <option value="AUD">Australian Dollar (AUD)</option>
        <option value="BRL">Brazilian Real 2 (BRL)</option>
        <option value="CAD">Canadian Dollar (CAD)</option>
        <option value="CNY">Chinese Renmenbi 4 (CNY)</option>
        <option value="CZK">Czech Koruna (CZK)</option>
        <option value="DKK">Danish Krone (DKK)</option>
        <option value="EUR">Euro (EUR)</option>
        <option value="HKD">Hong Kong Dollar (HKD)</option>
        <option value="HUF">Hungarian Forint 1 (HUF)</option>
        <option value="ILS">Israeli New Shekel (ILS)</option>
        <option value="JPY">Japanese Yen 1 (JPY)</option>
        <option value="MYR">Malaysian Ringgit 3 (MYR)</option>
        <option value="MXN">Mexican Peso (MXN)</option>
        <option value="TWD">New Taiwan Dollar 1 (TWD)</option>
        <option value="NZD">New Zealand Dollar (NZD)</option>
        <option value="NOK">Norwegian Krone (NOK)</option>
        <option value="PHP">Philippine Peso (PHP)</option>
        <option value="PLN">Polish Złoty (PLN)</option>
        <option value="GBP">Pound Sterling (GBP)</option>
        <option value="SGD">Singapore Dollar (SGD)</option>
        <option value="SEK">Swedish Krona (SEK)</option>
        <option value="CHF">Swiss Franc (CHF)</option>
        <option value="THB">Thai Baht (THB)</option>
        <option value="USD">United States Dollar (USD)</option>
    </select>
            </Form.Group>
                <Form.Group className='mb-2 col-md-6 form_details '>
                    <Form.Control
                        className='form-control_custom'
                        type="text"
                        id="amount"
                        name='amount'
                        value={formData.amount}
                        onChange={handleChangeAmount}
                        placeholder="Enter amount"
                    />
                </Form.Group>
               
                <Form.Group className='mb-2 col-md-6 form_details' >
                    <Form.Control
                        className='form-control_custom'
                        type="text"
                        id="product_name"
                          name='product_name'
                        value={formData.product_name}
                        onChange={(e) => setFormData({ ...formData, product_name: e.target.value })}
                        placeholder="Enter product name"
                    />
                </Form.Group>
                <Form.Group className='mb-2 col-md-6 form_details'>
                    <Form.Control
                        className='form-control_custom'
                        type="text"
                        id="quantity"
                        name='quantity'
                        value={formData.quantity}
                        onChange={(e) => setFormData({ ...formData, quantity: e.target.value })}
                        placeholder="Enter quantity"
                    />
                </Form.Group>
               
             
             <Form.Group className='mb-2 col-md-6 form_details '>
                    <Form.Control
                        className='form-control_custom'
                        type="hidden"
                        id="cur01"
                        name='cur01'
                        placeholder="Enter cur01"
                    />
                </Form.Group>

                </Form>
            
             <div id="paypal-buttons-container" disabled  ={!isFormValid} className='col-md-6 form_details'></div>
           
        </div>
  
        <div className="container mt-5">
      <table className="table table-bordered">
        <thead>
          <tr class="table-dark">
            <th>PayPal ID</th>
            <th>Captures</th>
            {/* <th>Captures Amount</th> */}
          
            {/* <th>Payer country Code</th> */}
            <th>Payer Given Name</th>
            <th>Payer</th>
            {/* <th>Captures Status</th> */}
            <th>PayPal </th>
            {/* <th>PayPal Account Status</th> */}
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data1 && data1.map((eventid) => (
            <tr key={eventid.id}>
              <td >{eventid.paypal_id} </td>
              <td >Id:{eventid.captures_id}<br></br>Amount:{eventid.captures_amount} <br></br>C.code: {eventid.captures_currency_code} <br></br>Status:{eventid.captures_status} </td>

              <td >{eventid.payer_given_name} {eventid.payer_surname}</td>

              <td>Email:{eventid.payer_email_address} <br></br>PayerId:{eventid.payer_payer_id} </td>
        
              <td>{eventid.paypal_email_address} <br></br>Status:{eventid.paypal_account_status}</td>
       
              <td className='text-center'>
                <span className={getStatusClass(eventid.captures_status)}>{eventid.captures_status}</span>
              </td>
              <td className='text-center'>
              <OverlayTrigger
                            key="View"
                            placement="top"
                            overlay={<Tooltip id={`View`}>Check Status</Tooltip>}
                          >
                         
                            
                            <Button
  variant="primary"
  style={{ "--i": "#27bcfd" }}
  onClick={() => {
    Submit(eventid.paypal_id);
    handleShow();
  }}
>
  Check Status
</Button>

                          </OverlayTrigger>
              </td>
              
            </tr>
          ))}
        </tbody>
      </table>
    </div>


    <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Order Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalData ? (
                        <pre>{JSON.stringify(modalData, null, 2)}</pre>
                    ) : (
                        "Loading..."
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default App;